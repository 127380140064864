// routes
import Router from './routes';
// theme
import ThemeConfig from './theme';
import GlobalStyles from './theme/globalStyles';
// components
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import { BaseOptionChartStyle } from './components/charts/BaseOptionChart';
import LoadingScreen, { ProgressBarStyle } from './components/LoadingScreen';
import { useDispatch } from './redux/store';
import { useEffect } from 'react';
import { getAllTariffs } from './redux/slices/tariffs';
import { useSelector } from 'react-redux';
import { getCategoriesThunk } from './redux/slices/category-tree';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { isDevicePWA } from './utils/utils';
import { PATH_AUTH, PATH_DASHBOARD } from './routes/paths';
import { firebaseConfig } from './config';
import firebase from 'firebase';
import {
  authGetTaskStatusThunk,
  authInitializeThunk,
  authLoginGoogleCodeThunk,
  authReduxSelector,
} from './redux/slices/authentication';
import { getPaymentStatus } from './redux/slices/user';
import '@inovua/reactdatagrid-enterprise/index.css';

// ----------------------------------------------------------------------

export default function App() {
  const { isInitialized, isAuthenticated } = useSelector(authReduxSelector);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const url = window.location.pathname;
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const code = searchParams.get('code');
    if (code) {
      authLoginGoogleCodeThunk(code).then(() => {
        searchParams.delete('code');
        searchParams.delete('scope');
        searchParams.delete('authuser');
        searchParams.delete('prompt');
        setSearchParams(searchParams);
      });
    }
    dispatch(getAllTariffs());
    dispatch(getCategoriesThunk());
    dispatch(authInitializeThunk());
    dispatch(getPaymentStatus());
    // dispatch(authGetTaskStatusThunk());
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
  }, [dispatch]);

  useEffect(() => {
    if (isDevicePWA() && !isAuthenticated) {
      navigate(PATH_AUTH.login);
    }

    if (isDevicePWA() && isAuthenticated) {
      navigate(PATH_DASHBOARD.general.auto_dumping);
    }

    if (isInitialized) {
      if (state && state.redirectPage) {
        navigate(state.redirectPage);
      } else if (!isAuthenticated && url.includes('cards')) {
        navigate(url.replace('/dashboard', ''));
      } else if (isAuthenticated && !url.includes('dashboard')) {
        navigate('/dashboard');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated, isInitialized]);

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <GlobalStyles />
              <ProgressBarStyle />
              <BaseOptionChartStyle />
              <ScrollToTop />
              <GoogleAnalytics />
              {isInitialized ? <Router /> : <LoadingScreen />}
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
