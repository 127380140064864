import { createSlice } from '@reduxjs/toolkit';

const name = 'business_spending';
const initialState = {
  filters: {},
};

const businessSpendingSlice = createSlice({
  name,
  initialState,
  reducers: {
    setFilters: (state, { payload }) => {
      state.filters = payload;
    },
  },
});

export const businessSpendingAction = businessSpendingSlice.actions;
export default businessSpendingSlice;
