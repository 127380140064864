import { Icon } from '@iconify/react';
import { useRef, useState } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Kaspi, Wildberries } from 'src/assets';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import {
  Box,
  Button,
  Divider,
  MenuItem,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/system';
import AccountBrands from './AccountBrands';
import { PATH_AUTH, PATH_DASHBOARD } from 'src/routes/paths';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { MAvatar, MIconButton } from 'src/components/@material-extend';
import MyAvatar from 'src/components/MyAvatar';
import MenuPopover from 'src/components/MenuPopover';
import { isDevicePWA } from '../../../utils/utils';
import toast from 'src/utils/snackBarUtils';
import { useDispatch, useSelector } from 'react-redux';
import {
  authReduxActions,
  authReduxSelector,
  authUpdateStoreThunk,
} from 'src/redux/slices/authentication';
import moment from 'moment';
import { KASPI, WILDBERRIES } from 'src/utils/marketplace';
import { actionMarketplace } from 'src/redux/slices/marketplace';
import CustomTooltip from 'src/components/_dashboard/my-analytics/UI/TextTruncateTooltip';
import IconButton from 'src/theme/overrides/IconButton';
import styled from '@emotion/styled';
import CustomModal from 'src/components/customModal/CustomModal';
import { DATA_IDS } from 'src/utils/cypress';
import { setDataTestId } from 'src/utils/setDataTestId';
import createAvatar from '../../../utils/createAvatar';

const AccountWrap = styled(MAvatar)`
  width: 52px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 14px;
  border: 1px solid rgba(9, 36, 75, 0.2);
`;

const {
  home_link_btn,
  setting_link_btn,
  subscribe_btn,
  logout_btn,
  wildberries_link_btn,
  modal_submit_btn,
  navbar,
} = DATA_IDS.dashboard;

const MENU_OPTIONS = [
    {
    label: 'Настройки аккаунта',
    icon: settings2Fill,
    linkTo: PATH_DASHBOARD.user.account,
    testId: setting_link_btn,
  },
  {
    label: 'Тарифы',
    icon: settings2Fill,
    linkTo: PATH_DASHBOARD.user.account,
    testId: setting_link_btn,
  },
  {
    label: 'Для партнеров',
    icon: settings2Fill,
    linkTo: PATH_DASHBOARD.user.account,
    testId: setting_link_btn,
    disabled: true,
  },
  {
    label: 'История заказов',
    icon: settings2Fill,
    linkTo: PATH_DASHBOARD.general.catalog_orders,
    testId: setting_link_btn,
  },
  {
    label: 'Мои товары',
    icon: settings2Fill,
    linkTo: PATH_DASHBOARD.general.my_products,
    testId: setting_link_btn,
  },
  {
    label: 'Баланс',
    icon: settings2Fill,
    linkTo: PATH_DASHBOARD.general.balance,
    testId: setting_link_btn,
  },
];

const MOBILE_MENU_OPTIONS = [
  ...MENU_OPTIONS,
  {
    label: 'Тарифы',
    linkTo: PATH_DASHBOARD.user.billing,
    icon: 'ic:round-payments',
  },
];

const userAccountOption = {
  label: 'Добавить токен',
  linkTo: PATH_DASHBOARD.user.account,
  icon: 'mdi:account-card',
};

const StyledWbManuItem = styled(MenuItem)`
  padding: 0.4rem 1.2rem;
  background-color: transparent;
  color: ${(props) => props.theme.palette.text.primary};
  border: 2px solid
    ${(props) => (props.isActive ? props.theme.palette.primary.main : 'white')};
  border-radius: 12px;
  &:hover {
    background-color: transparent;
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

const StyledKaspiMenuItem = styled(MenuItem)`
  padding: 0.4rem 1.2rem;
  background-color: transparent;
  color: ${(props) => props.theme.palette.text.primary};
  border: 2px solid
    ${(props) => (props.isActive ? props.theme.palette.error.main : 'white')};
  border-radius: 12px;
  &:hover {
    background-color: transparent;
    color: ${(props) => props.theme.palette.text.primary};
  }
`;

// ----------------------------------------------------------------------

export default function AccountPopover({ checkTaskStatus }) {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const isMountedRef = useIsMountedRef();
  const { user } = useSelector((state) => authReduxSelector(state));
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const { paymentDaysRemain } = useSelector((state) => state.user);
  const { currentMarketplace } = useSelector((state) => state.marketplace);

  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const MOBILE_OPTION = [
    ...MOBILE_MENU_OPTIONS,
    ...(!user?.is_user_added_token ? [userAccountOption] : []),
  ];

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleSwitch = (value) => {
    const currentMarketPlace = value !== 'wb' ? WILDBERRIES : KASPI;

    dispatch(actionMarketplace.switchMarketplace(currentMarketPlace));
    dispatch(
      authUpdateStoreThunk({
        current_marketplace: currentMarketPlace,
      }),
    );

    navigate('/dashboard');
  };

  const handleLogout = async () => {
    try {
      dispatch(authReduxActions.logout());
      if (isDevicePWA()) {
        navigate(PATH_AUTH.login);
      } else {
        navigate('/');
      }
      if (isMountedRef.current) {
        handleClose();
      }
    } catch (error) {
      console.error(error);
      toast.error('Unable to logout');
    }
  };

  const handleOpenModal = () => {
    setModal(true);
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  const handleBilling = () => {
    navigate(PATH_DASHBOARD.user.billing);
    handleClose();
  };

  const handleWbKaspiChange = (option) => {
    if (option !== 'Kaspi') {
      handleSwitch('kaspi');
    } else {
      handleSwitch('wb');
    }
    handleClose();
  };

  return (
    <>
      <CustomModal
        isOpen={modal}
        title={'Вы действительно хотите выйти из системы'}
        submitBtnTitle={'Выйти'}
        data_test_id={modal_submit_btn}
        onSubmit={() => handleLogout()}
        onClose={() => handleCloseModal()}
      />
      <AccountWrap
        ref={anchorRef}
        onClick={handleOpen}
        {...setDataTestId({ name: navbar.user_avatar_btn })}
        src={user?.profile_image}
        alt={user?.full_name}
        color={user?.photoURL ? 'default' : createAvatar(user?.email).color}
        sx={{ cursor: 'pointer' }}
      >
        {createAvatar(user?.email).name}
      </AccountWrap>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user?.displayName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        {(isMobile ? MOBILE_OPTION : MENU_OPTIONS).map((option) => (
          <MenuItem
            key={option.label}
            to={option.linkTo}
            component={RouterLink}
            onClick={handleClose}
            disabled={option.disabled}
            sx={{ typography: 'body2', py: 1, px: 2.5 }}
            {...setDataTestId({ name: option.testId })}
          >
            {typeof option.icon === 'function' ? (
              <div style={{ marginRight: '1.2rem' }}>
                <Wildberries width={20} height={20} />
              </div>
            ) : (
              <>
                <Box
                  component={Icon}
                  icon={option.icon}
                  sx={{
                    mr: 2,
                    width: 24,
                    height: 24,
                  }}
                />
              </>
            )}

            {option.label}
          </MenuItem>
        ))}
        {user?.is_user_added_token && isMobile && (
          <Box component="div" sx={{ p: 2, pt: 1.5, pb: 0 }}>
            <AccountBrands
              checkTaskStatus={checkTaskStatus}
              isInPopover={true}
            />
          </Box>
        )}
        <Box component="div" sx={{ p: 2 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleBilling}
            sx={{ textTransform: 'none' }}
            {...setDataTestId({ name: subscribe_btn })}
          >
            {paymentDaysRemain <= 0
              ? 'Продлить подписку'
              : `Подписка до:${moment()
                  .add(+paymentDaysRemain, 'days')
                  .format('DD.MM.YY')}`}
          </Button>
        </Box>
        <StyledWbManuItem
          component={RouterLink}
          to={PATH_DASHBOARD.general.analytics}
          onClick={() => handleWbKaspiChange('Wildberries')}
          isActive={currentMarketplace === WILDBERRIES}
          {...setDataTestId({ name: wildberries_link_btn })}
        >
          <Box
            sx={{
              padding: 0.6,
              marginRight: 1,
            }}
          >
            <div>
              <Wildberries width={22} height={22} />
            </div>
          </Box>
          Wildberries
        </StyledWbManuItem>
        <StyledKaspiMenuItem
          disabled
          component={RouterLink}
          to={PATH_DASHBOARD.general.auto_dumping}
          onClick={() => handleWbKaspiChange('KASPI')}
          isActive={currentMarketplace === KASPI}
        >
          <Box
            sx={{
              padding: 0.6,
              marginRight: 1,
            }}
          >
            <div>
              <Kaspi width={25} height={25} />
            </div>
          </Box>
          Kaspi
          <span style={{ fontWeight: 700, marginLeft: 5 }}>(скоро)</span>
        </StyledKaspiMenuItem>
        <StyledKaspiMenuItem
          disabled
          component={RouterLink}
          to={PATH_DASHBOARD.general.auto_dumping}
          onClick={() => handleWbKaspiChange('Kaspi')}
          isActive={currentMarketplace === KASPI}
        >
          <Box
            sx={{
              padding: 0.6,
              marginRight: 1,
            }}
          >
            <div>
              <Kaspi width={25} height={25} />
            </div>
          </Box>
          OZON
          <span style={{ fontWeight: 700, marginLeft: 5 }}>(скоро)</span>
        </StyledKaspiMenuItem>
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={handleOpenModal}
            {...setDataTestId({ name: logout_btn })}
          >
            Выйти
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
