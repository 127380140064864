import { fPrice } from './formatNumber';

const createColumn = ({
  _id,
  name,
  header,
  width = 120,
  visible = true,
  index,
  locked = false,
  type = 'all',
  render = null,
}) => ({
  _id,
  name,
  header,
  width,
  visible,
  index,
  locked,
  type,
  render,
});

export const processItems = (items, key, type, typeDates) => {
  return items.map((item, index) => {
    const itemWithKey = item[`${key}_table`];

    let items = item[`${key}_table`];
    if (!Array.isArray(itemWithKey)) {
      items = [];
    }

    const processedData = items?.reduce((prev, curr) => {
      prev[curr.dt] = curr[`${key}_count`];
      typeDates[curr.dt] = 'ok';
      return prev;
    }, {});

    return {
      ...item,
      ...processedData,
      index,
      rating: item.feedback.rating,
      sales_count: item.sales.count,
      sales: item.sales.sales,
      orders_count: item.orders.orders_count,
      orders_sum: item.orders.orders_sum,
      margin_from_price: item.margin_from_price,
      margin_amount: item.margin_amount,
      buyout_percentage: item.buy_percent,
    };
  });
};

export const productClassificationType = {
  article: 'article',
  size: 'size',
};

export const orderType = {
  order: 'orders',
  sale: 'sales',
};

export const columns = {
  all: [
    createColumn({
      _id: 100,
      name: 'imt_id',
      header: '№',
      index: 1,
      locked: true,
    }),
    createColumn({
      _id: 11,
      name: 'url',
      header: 'Ссылка',
      width: 127,
      index: 2,
      locked: true,
      render: ({ value }) => (
        <a target="_blank" href={value} rel="noreferrer">
          {value}
        </a>
      ),
    }),
    createColumn({
      _id: 12,
      name: 'subject',
      header: 'Категория',
      index: 3,
      locked: true,
    }),
    createColumn({
      _id: 14,
      name: 'supplier_article',
      header: 'Артикул продавца',
      index: 5,
      locked: true,
    }),
    createColumn({
      _id: 15,
      name: 'nm_id',
      header: 'Артикул WB',
      index: 9,
      locked: true,
    }),
    createColumn({ _id: 1, name: 'abc', header: 'ABC', width: 75, index: 10 }),
    createColumn({
      _id: 2,
      name: 'feedback',
      header: 'Отзывы',
      index: 11,
      render: ({ value, data }) =>
        data.type !== 'empty' ? fPrice(value?.feedbacks || 0) : '',
    }),
    createColumn({
      _id: 3,
      name: 'rating',
      header: 'Рейтинг',
      index: 12,
      render: ({ value, data }) =>
        data.type !== 'empty' ? fPrice(value || 0) : '',
    }),
    createColumn({
      _id: 4,
      name: 'ctr',
      header: 'CTR, %',
      index: 13,
      render: ({ value, data }) =>
        data.type !== 'empty' ? `${value?.ctr || 0}%` : '',
    }),
    createColumn({
      _id: 5,
      name: 'cr',
      header: 'CR, %',
      index: 14,
      render: ({ value, data }) =>
        data.type !== 'empty' ? `${value?.total_open_card_count || 0}%` : '',
    }),
    createColumn({
      _id: 6,
      name: 'buyout_percentage',
      header: 'Выкуп, %',
      index: 15,
      render: ({ value, data }) =>
        data.type !== 'empty' ? fPrice(value || 0) : '',
    }),
    createColumn({
      _id: 7,
      name: 'margin_from_price',
      header: 'Маржа от цены, %',
      index: 16,
      width: 180,
      render: ({ value, data }) =>
        data.type !== 'empty' ? fPrice(value || 0) : '',
    }),
    createColumn({
      _id: 8,
      name: 'margin_amount',
      header: 'Маржа, руб',
      index: 17,
      render: ({ value, data }) =>
        data.type !== 'empty' ? fPrice(value || 0) : '',
    }),
    createColumn({
      _id: 9,
      name: 'price',
      header: 'Цена, руб',
      index: 18,
      render: ({ value, data }) =>
        data.type !== 'empty' ? fPrice(value || 0) : '',
    }),
    createColumn({
      _id: 16,
      name: 'to_client',
      header: 'В пути (до), шт',
      index: 19,
      render: ({ value, data }) =>
        data.type !== 'empty' ? `${value || 0}шт` : '',
    }),
    createColumn({
      _id: 17,
      name: 'from_client',
      header: 'В пути (от), шт',
      index: 20,
      render: ({ value, data }) =>
        data.type !== 'empty' ? `${value || 0}шт` : '',
    }),
    createColumn({
      _id: 17,
      name: 'order_turnover_dynamics',
      header: 'ДО\n(з)',
      index: 21,
      render: ({ value, data }) => (data.type !== 'empty' ? value || 0 : ''),
    }),
    createColumn({
      _id: 17,
      name: 'sale_turnover_dynamics',
      header: 'ДО\n(п)',
      index: 21,
      render: ({ value, data }) => (data.type !== 'empty' ? value || 0 : ''),
    }),
    createColumn({
      _id: 17,
      name: 'left_count',
      header: 'Остаток',
      index: 22,
      render: ({ value, data }) => (data.type !== 'empty' ? value || 0 : ''),
    }),
    // Удалён вызов ...generateDateColumns()
  ],
  [orderType.sale]: [
    createColumn({
      _id: 145,
      name: 'sales_count',
      header: 'Продажи, шт',
      index: 24,
      width: 130,
      type: orderType.sale,
      render: ({ value, data }) =>
        data.type !== 'empty' ? fPrice(value || 0) : '',
    }),
    createColumn({
      _id: 254,
      name: 'sales',
      header: 'Продажи, руб',
      index: 25,
      type: orderType.sale,
      render: ({ value, data }) =>
        data.type !== 'empty' ? fPrice(value || 0) : '',
    }),
  ],
  [orderType.order]: [
    createColumn({
      _id: 1,
      name: 'orders_count',
      header: 'Заказы, шт',
      index: 26,
      width: 130,
      type: orderType.order,
      render: ({ value, data }) =>
        data.type !== 'empty' ? fPrice(value || 0) : '',
    }),
    createColumn({
      _id: 2438,
      name: 'orders_sum',
      header: 'Заказы, руб',
      index: 27,
      type: orderType.order,
      render: ({ value, data }) =>
        data.type !== 'empty' ? fPrice(value || 0) : '',
    }),
  ],
  [productClassificationType.size]: [
    createColumn({
      _id: 1,
      name: 'barcode',
      header: 'Баркод (ШК)',
      width: 130,
      index: 4,
      type: productClassificationType.size,
      render: ({ value }) => value || '',
    }),
    createColumn({
      _id: 225,
      name: 'wb_size',
      header: 'Размер',
      index: 6,
      type: productClassificationType.size,
      render: ({ value }) => value || '',
    }),
    createColumn({
      _id: 234,
      name: 'tech_size',
      header: 'Рос. размер',
      index: 7,
      type: productClassificationType.size,
      render: ({ value }) => value || '',
    }),
    createColumn({
      _id: 246,
      name: 'color',
      header: 'Цвет',
      index: 8,
      type: productClassificationType.size,
      render: ({ value }) => value || '',
    }),
  ],
  [productClassificationType.article]: [],
};
