import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import {
  AppBar,
  Box,
  Stack,
  Toolbar,
  Typography,
  Menu,
  MenuItem,
  InputBase,
  IconButton,
  Badge,
} from '@mui/material';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// SVG-иконки
import { ReactComponent as LinkIcon } from '../../../assets/LinkIcon.svg';
import { ReactComponent as SearchIcon } from '../../../assets/Search.svg';
import { ReactComponent as FavoriteIcon } from '../../../assets/FavoriteIcon.svg';
import { ReactComponent as Cart } from '../../../assets/Bag.svg';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
//
import AccountPopover from './AccountPopover';
import MainLogo from '../../../assets/MainLogo.png';
//
import { useSelector } from 'react-redux';

const RootStyle = styled(AppBar)({
  boxShadow: 'none',
  background: '#f8f1f9',
  display: 'flex',
  justifyContent: 'center',
  padding: '0 12px',
});

const StyledToolbar = styled(Toolbar)({
  height: '84px',
  background: '#fff',
  borderRadius: '0 0 16px 16px',
  display: 'grid',
  gridTemplateColumns: '1fr 3fr 2fr',
});

const StyledRouterLink = styled(RouterLink)({
  width: '116px',
  height: '34px',
});

const NavItem = styled(Box)({
  display: 'flex',
  marginRight: '10px',
  alignItems: 'center',
  cursor: 'pointer',
});

const SearchWrap = styled(Box)({
  width: '52px',
  height: '52px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '14px',
  border: '1px solid rgba(9, 36, 75, 0.2)',
  cursor: 'pointer',
});

const SearchBar = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '14px',
  border: '1px solid #09244ba2',
  height: '52px',
  transition: 'all 300ms ease-in-out',
});

// Твоё меню навигации
export const navigations = [
  {
    title: 'Каталог',
    path: PATH_DASHBOARD.general.catalog,
    icon: <LinkIcon />,
  },
  {
    title: 'Аналитика',
    children: [
      { title: 'Дашборд', path: PATH_DASHBOARD.general.shop, disabled: false },
      {
        title: 'Оцифровка кабинета',
        path: PATH_DASHBOARD.general.digitization,
        disabled: false,
      },
      {
        title: 'Аналитика конкурентов',
        path: PATH_DASHBOARD.general.category_analytics,
        disabled: false,
      },
      {
        title: 'Финансовый отчет',
        path: PATH_DASHBOARD.general.financial_report,
        disabled: false,
      },
      {
        title: 'Контроль поставок и себестоимости',
        path: PATH_DASHBOARD.general.category_analytics,
        disabled: true,
      },
      {
        title: 'Подбор ниши',
        path: PATH_DASHBOARD.general.selectiony,
        disabled: true,
      },
      {
        title: 'Внешняя реклама',
        path: PATH_DASHBOARD.general.advertising,
        disabled: true,
      },
    ],
  },
  {
    title: 'Инструменты',
    children: [
      {
        title: 'UNIT-экономика (плановая)',
        path: PATH_DASHBOARD.general.profit_calculator,
        disabled: false,
      },
      {
        title: 'SEO-оптимизация',
        path: PATH_DASHBOARD.general.semantic_core,
        disabled: false,
      },
      {
        title: 'База подрядчиков',
        path: PATH_DASHBOARD.user.cards,
        disabled: false,
      },
      {
        title: 'Генератор описания',
        path: `${PATH_DASHBOARD.general.description_generator}/false`,
        disabled: false,
      },
      {
        title: 'Мониторинг позиций',
        path: PATH_DASHBOARD.general.monitoring_of_positions,
        disabled: true,
      },
      {
        title: 'Баркодер',
        path: PATH_DASHBOARD.general.generate_barcode,
        disabled: true,
      },
      {
        title: 'A/B-тест',
        path: PATH_DASHBOARD.general.monitoring_of_positions,
        disabled: true,
      },
      {
        title: 'Автобиддер',
        path: PATH_DASHBOARD.general.monitoring_of_positions,
        disabled: true,
      },
    ],
  },
  {
    title: 'Обучение',
    children: [
      {
        title: 'Инструкции по сервису',
        path: PATH_DASHBOARD.general.video_instructor,
        disabled: false,
      },
      {
        title: 'Видео-уроки',
        path: PATH_DASHBOARD.general.video_teaching,
        disabled: false,
      },
    ],
  },
  {
    title: 'CARTEL',
    children: [
      { title: 'О клубе', path: PATH_DASHBOARD.general.forum, disabled: true },
      {
        title: 'Новости клуба',
        path: PATH_DASHBOARD.general.forum,
        disabled: true,
      },
      { title: 'Чат клуба', path: PATH_DASHBOARD.user.cards, disabled: true },
      {
        title: 'Календарь мероприятий',
        path: PATH_DASHBOARD.user.cards,
        disabled: true,
      },
    ],
  },
];

export default function DashboardNavbar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentMenu, setCurrentMenu] = useState(null);
  const handleToggleSearch = () => {
    setShowSearch((prev) => !prev);
  };

  // Состояния для поисковика
  const [showSearch, setShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');

  const { favorites = [] } = useSelector(
    (state) => state.catalog_favorites || {},
  );
  const { totalQuantity = 0 } = useSelector((state) => state.cart || {});
  const navigate = useNavigate();

  // Открытие подменю
  const handleMenuOpen = (event, menu) => {
    if (menu.children) {
      setAnchorEl(event.currentTarget);
      setCurrentMenu(menu);
    } else if (menu.path) {
      navigate(menu.path);
    }
  };

  // Закрыть подменю
  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentMenu(null);
  };

  // Парсим артикул из строки (либо цифры, либо ссылка)
  const parseArticle = (value) => {
    const trimmed = value.trim();

    // Если строка — чисто цифры, вернём их
    if (/^\d+$/.test(trimmed)) {
      return trimmed;
    }

    // Если ссылка на wildberries, ищем кусок вида /catalog/(\d+)/detail.aspx
    // домен может быть .ru / .kg / .by и т.д., потому упростим:
    // .+?wildberries\.\w+/catalog/(\d+)/detail\.aspx
    // Но проще ищем catalog/(\d+)/detail.aspx - этого достаточно
    const match = trimmed.match(/catalog\/(\d+)\/detail\.aspx/);
    if (match && match[1]) {
      return match[1]; // вернём именно цифры из ссылки
    }

    return null; // если не нашли
  };

  // При нажатии на иконку поиска или Enter
  const handleSearch = () => {
    const article = parseArticle(searchQuery);
    if (article) {
      // Открываем нужную ссылку:
      window.location.href = `/dashboard/analytics/${article}`;
      // Или, если у тебя есть внутренний роут:
      // navigate(`/dashboard/analytics/${article}`);
    } else {
      alert(
        'Неправильный формат! Введите артикул или ссылку на товар Wildberries.',
      );
    }
  };

  const handleChangeSearchQuery = ({ target }) => {
    setSearchQuery(target.value);
  };

  const handleClickSearchIcon = () => {
    if (!searchQuery) {
      handleToggleSearch();
      return;
    }
    handleSearch();
  };

  // Срабатывает при нажатии Enter
  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  // Рендер пунктов подменю
  const renderMenuItems = (items) =>
    items.map((item) => (
      <MenuItem
        key={item.title}
        component={item.disabled ? Box : RouterLink}
        to={!item.disabled ? item.path : undefined}
        onClick={!item.disabled ? handleMenuClose : undefined}
        sx={{
          color: item.disabled ? 'grey' : '#09244B',
          cursor: item.disabled ? 'not-allowed' : 'pointer',
          '&:hover': {
            background: item.disabled ? 'none' : '#f8f8f8',
          },
        }}
      >
        <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>
          {item.title} {item.disabled && '(скоро)'}
        </Typography>
      </MenuItem>
    ));

  return (
    <RootStyle>
      <StyledToolbar>
        {/* Лого */}
        <StyledRouterLink to={PATH_DASHBOARD.root}>
          <Box
            ml="30px"
            component="img"
            alt="isistant-logo"
            src={`${MainLogo}`}
          />
        </StyledRouterLink>

        <Box display="flex" justifyContent="flex-end">
          {navigations.map((item) => (
            <NavItem
              key={item.title}
              onClick={(e) => handleMenuOpen(e, item)}
              sx={{
                mr: '35px',
                '&:hover': { borderBottom: '2px solid #09244B' },
              }}
            >
              {item.icon}
              <Typography
                sx={{ fontSize: '16px', fontWeight: '700', color: '#09244B' }}
              >
                {item.title}
              </Typography>
              {item.children && (
                <ArrowDropDownIcon style={{ color: '#09244B' }} />
              )}
            </NavItem>
          ))}
        </Box>

        <Stack direction="row" gap={1.5}>
          <Box width="300px" display="grid" justifyContent="flex-end">
            <SearchBar
              sx={{
                width: showSearch ? '300px' : '52px',
                justifyContent: 'flex-end',
              }}
            >
              <InputBase
                sx={
                  showSearch ? { ml: 1, flex: 1 } : { width: 0, ml: 0, flex: 0 }
                }
                placeholder="Поиск по SKU или ссылка"
                onKeyDown={handleKeyDown}
                onChange={handleChangeSearchQuery}
              />
              <IconButton
                onClick={handleClickSearchIcon}
                sx={{ minWidth: '52px' }}
              >
                <SearchIcon />
              </IconButton>
            </SearchBar>
          </Box>

          <Badge
            badgeContent={favorites.length}
            max={99}
            sx={{ span: { background: '#EC407A' } }}
          >
            <SearchWrap
              component={RouterLink}
              to={PATH_DASHBOARD.general.catalog_favorites}
            >
              <FavoriteIcon />
            </SearchWrap>
          </Badge>

          <Badge
            badgeContent={totalQuantity}
            max={99}
            sx={{ span: { background: '#EC407A' } }}
          >
            <SearchWrap
              component={RouterLink}
              to={PATH_DASHBOARD.general.checkout}
            >
              <Cart />
            </SearchWrap>
          </Badge>

          {/* Меню аккаунта */}
          <AccountPopover />
        </Stack>

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          {currentMenu?.children && renderMenuItems(currentMenu.children)}
        </Menu>
      </StyledToolbar>
    </RootStyle>
  );
}
