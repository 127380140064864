import { createSlice } from '@reduxjs/toolkit';
const defaultFiltersValue = {
  grouping: 'size',
  method: 'calendar',
  brand: 'all',
  item: 'all',
};
const name = 'cost_supply';
const initialState = {
  filters: defaultFiltersValue,
  date: {},
};

const costSupplyslice = createSlice({
  name,
  initialState,
  reducers: {
    setFilters: (state, { payload }) => {
      state.filters = payload.filters;
      state.date = payload.date;
    },
  },
});
export const costSupplyAction = costSupplyslice.actions;
export default costSupplyslice;
