import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import { API } from 'src/apis';
import axiosInstance from 'src/utils/axios';
import toast from 'src/utils/snackBarUtils';
import { v4 } from 'uuid';

export const getSummary = createAsyncThunk(
  'financialReport/getSummary',
  async (params = {}) => {
    try {
      const { date_from, date_to } = params;
      const { data } = await axiosInstance.get(API.seller_finreport_summary, {
        params: { date_from, date_to },
      });

      return data;
    } catch (error) {
      toast.error('Не удалось получить данные сводного отчета');
    }
  },
);

export const getDetalization = createAsyncThunk(
  'financialReport/getDetalization',
  async (params) => {
    try {
      const { groupBy, date_from, date_to } = params;
      const { data } = await axiosInstance.get(API.seller_finreport_table, {
        params: {
          group_by: groupBy,
          date_from,
          date_to,
        },
      });

      return data;
    } catch (error) {
      toast.error('Не удалось получить данные детализации');
    }
  },
);

export const getGoodsCost = createAsyncThunk(
  'financialReport/getGoodsCost',
  async () => {
    try {
      const { data } = await axiosInstance.get(
        API.seller_finreport_cost_barcodes,
      );

      return data;
    } catch (error) {
      toast.error('Не удалось получить данные базовой себестоимости товаров');
    }
  },
);

export const changeCostPrice = createAsyncThunk(
  'financialReport/changeCostPrice',
  async (params) => {
    try {
      await axiosInstance.post(API.seller_finreport_cost, params);
    } catch (error) {
      toast.error('Не удалось изменить себестоимость товара');
    }
  },
);

const initialState = {
  summary: { data: {}, isLoading: false },
  detalization: { data: [], isLoading: false },
  goodsCost: { data: [], isLoading: false },
};

const financialReportSlice = createSlice({
  name: 'financialReport',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDetalization.pending, (state) => {
        state.detalization.isLoading = true;
      })
      .addCase(getDetalization.fulfilled, (state, action) => {
        const detalizationPayload = action.payload;
        const detalization = detalizationPayload
          ? detalizationPayload.map((row) => ({
              ...row,
              id: row.report_id,
            }))
          : [];

        state.detalization.isLoading = false;
        state.detalization.data = detalization;
      })
      .addCase(getSummary.pending, (state) => {
        state.summary.isLoading = true;
      })
      .addCase(getSummary.fulfilled, (state, action) => {
        const summary = action.payload;

        state.summary.isLoading = false;
        state.summary.data = summary || {};
      })
      .addCase(getGoodsCost.fulfilled, (state, action) => {
        const goodsCostPayload = action.payload;
        const goodsCost = goodsCostPayload
          ? goodsCostPayload.map((cost) => ({ ...cost, id: v4() }))
          : [];

        state.goodsCost.isLoading = false;
        state.goodsCost.data = goodsCost;
      })
      .addCase(getGoodsCost.pending, (state) => {
        state.goodsCost.isLoading = true;
      })
  },
});

const selectSelf = (state) => state.financialReport;
export const detalizationSelector = createSelector(
  selectSelf,
  (state) => state.detalization.data,
);
export const isLoadingDetalizationSelector = createSelector(
  selectSelf,
  (state) => state.detalization.isLoading,
);
export const summarySelector = createSelector(
  selectSelf,
  (state) => state.summary.data,
);
export const isLoadingSummarySelector = createSelector(
  selectSelf,
  (state) => state.summary.isLoading,
);
export const goodsCostSelector = createSelector(
  selectSelf,
  (state) => state.goodsCost.data,
);
export const isLoadingGoodsCostSelector = createSelector(
  selectSelf,
  (state) => state.goodsCost.isLoading,
);

export default financialReportSlice;
