import React from 'react';
import { styled } from '@mui/material/styles';
import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import { PATH_AUTH, PATH_DASHBOARD, PATH_PAGE } from 'src/routes/paths';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import MainLogo from '../../assets/MainLogo.png';
import { authReduxSelector } from '../../redux/slices/authentication';
import { useSelector } from 'react-redux';

const RootStyle = styled(AppBar)(() => ({
  boxShadow: 'none',
  background: 'none',
  display: 'flex',
  justifyContent: 'center',
  padding: '0 12px',
}));

const StyledToolbar = styled(Toolbar)`
  height: 84px;
  background: #fff;
  border-radius: 0 0 16px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  box-shadow:
    0 0 2px 0 rgba(145, 158, 171, 0.24),
    0 16px 32px -4px rgba(145, 158, 171, 0.24);
`;

const StyledRouterLink = styled(RouterLink)`
  width: 116px;
  height: 34px;
`;

const NavigationContainer = styled(Box)`
  display: flex;
`;

const NavItem = styled(Box)`
  display: flex;
  justify-content: center;
  margin-right: 10px;
  align-items: center;
  cursor: pointer;
`;

export default function MainNavbar() {
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => authReduxSelector(state));

  const portalSpecialistLink = isAuthenticated
    ? PATH_DASHBOARD.user.cards
    : PATH_PAGE.portal_specialist;

  const formLink = isAuthenticated
    ? PATH_DASHBOARD.general.forum
    : PATH_PAGE.forum;

  return (
    <RootStyle>
      <StyledToolbar>
        <StyledRouterLink to={PATH_DASHBOARD.root}>
          <Box
            ml="30px"
            component="img"
            alt="isistant-logo"
            src={`${MainLogo}`}
          />
        </StyledRouterLink>
        <NavigationContainer>
          <NavItem onClick={() => navigate(formLink)} sx={{ mr: '35px' }}>
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '700',
                color: '#09244B',
              }}
            >
              Форум
            </Typography>
          </NavItem>

          <NavItem
            onClick={() => navigate(PATH_PAGE.price)}
            sx={{ mr: '35px' }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '700',
                color: '#09244B',
              }}
            >
              Тарифы
            </Typography>
          </NavItem>

          <NavItem
            onClick={() => navigate(portalSpecialistLink)}
            sx={{ mr: '35px' }}
          >
            <Typography
              sx={{
                fontSize: '16px',
                fontWeight: '700',
                color: '#09244B',
              }}
            >
              База подрядчиков
            </Typography>
          </NavItem>

          {isAuthenticated ? (
            <NavItem
              onClick={() => navigate(PATH_DASHBOARD.root)}
              sx={{ mr: '35px' }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  fontWeight: '700',
                  color: '#09244B',
                }}
              >
                Дашборд
              </Typography>
            </NavItem>
          ) : (
            <NavItem
              onClick={() => navigate(PATH_AUTH.login)}
              sx={{
                mr: '35px',
                background: '#616CF547',
                borderRadius: '30px',
                padding: '10px 40px',
                width: '100px',

                '&:hover': {
                  background: '#616CF5',
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: '18px',
                  fontWeight: '800',
                  color: '#09244B',
                }}
              >
                Войти
              </Typography>
            </NavItem>
          )}
        </NavigationContainer>
      </StyledToolbar>
    </RootStyle>
  );
}
