import { Icon } from '@iconify/react';
import { useSnackbar } from 'notistack';
import { MIconButton } from 'src/components/@material-extend';
import closeFill from '@iconify/icons-eva/close-fill';

const InnerSnackbarUtilsConfigurator = (props) => {
  props.setUseSnackbarRef(useSnackbar());
  return null;
};

let useSnackbarRef;
const setUseSnackbarRef = (useSnackbarRefProp) => {
  useSnackbarRef = useSnackbarRefProp;
};

export const SnackbarUtilsConfigurator = () => {
  return (
    <InnerSnackbarUtilsConfigurator setUseSnackbarRef={setUseSnackbarRef} />
  );
};

const toast = {
  __load(message, variant, options) {
    if (Array.isArray(message)) {
      for (const msg of message) {
        this.toast(msg, variant, options);
      }
    } else {
      this.toast(message, variant, options);
    }
  },
  success(msg, options) {
    this.__load(msg, 'success', options);
  },
  warning(msg, options) {
    this.__load(msg, 'warning', options);
  },
  info(msg, options) {
    this.__load(msg, 'info', options);
  },
  error(msg, options) {
    if (msg.details) {
      this.__load(msg.details, 'error', options);
    } else {
      this.__load(msg, 'error', options);
    }
  },
  toast(msg, variant = 'default', otherOptions) {
    const checkOtherOptions = otherOptions || {};

    if (typeof useSnackbarRef?.enqueueSnackbar === 'function') {
      useSnackbarRef?.enqueueSnackbar(msg, {
        variant,
        action: (key) => (
          <MIconButton
            size="small"
            onClick={() => useSnackbarRef?.closeSnackbar(key)}
          >
            <Icon icon={closeFill} />
          </MIconButton>
        ),
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        ...checkOtherOptions,
      });
    }
  },
};

export default toast;
